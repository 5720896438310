<template>
  <section ref="rootEl" class="keyfacts-text mt-xxxl">
    <h2
      ref="titleEl"
      class="keyfacts-text__title wrapper mb-m"
      :class="`title-${content.titleColor}`"
      v-html="content.title"
    ></h2>
    <div class="keyfacts-text__content">
      <ol class="keyfacts-text__content__items p-xl">
        <li
          v-for="item in content.items"
          ref="itemsEl"
          :key="item.title"
          class="keyfacts-text__content__item"
          :class="item.color ? `bg-${item.color}` : 'bg-cobalt'"
        >
          <div class="keyfacts-text__content__item-inner">
            <strong class="h3">{{ item.title }}</strong>
            <div class="text" v-html="item.text"></div>
          </div>
        </li>
      </ol>

      <figure
        v-if="content.picture"
        ref="pictureEl"
        class="keyfacts-text__content__picture-outer"
      >
        <img
          v-src="content.picture"
          class="keyfacts-text__content__picture"
          :alt="content.picture.alt"
          :sets="['160', '480', '640', '960']"
          sizes="(min-width: 1680px) 960w, (min-width: 1024px) 50vw, 100px"
        />
      </figure>
    </div>

    <KeyfactsShared
      v-if="content.missing_items"
      type="text"
      :content="{
        title: content.missing_title,
        items: content.missing_items,
      }"
      :title-color="content.titleColor"
      :color="content.background_color"
    ></KeyfactsShared>
  </section>
</template>

<script lang="ts">
import type { KeyfactsSharedItem } from '@/components/keyfacts/Shared.vue'
import type { Picture } from '@/types'

interface KeyfactsText {
  titleColor?: string
  missing_title: string
  background_color: string
  picture: Picture
  missing_items: KeyfactsSharedItem[]
  title: string
  items: {
    title: string
    text: string
    color: string
  }[]
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import KeyfactsShared from '@/components/keyfacts/Shared.vue'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<KeyfactsText>,
    required: true,
  },
})

// Refs
const rootEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const itemsEl = ref<HTMLElement[]>([])
const pictureEl = ref<HTMLElement | null>(null)

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value, ...itemsEl.value]
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })
  const duration = 0.5
  const ease = '8020'

  tl.fromTo(rootEl.value, { opacity: 0 }, { opacity: 1, duration, ease }, 0)

  if (titleEl.value) {
    animatedEls.push(titleEl.value)

    tl.from(titleEl.value, { opacity: 0, y: 20, duration, ease }, 0)
  }

  tl.from(
    itemsEl.value,
    { xPercent: -30, opacity: 0, duration, ease, stagger: 0.05 },
    0
  )

  if (pictureEl.value) {
    animatedEls.push(pictureEl.value)

    tl.from(pictureEl.value, { opacity: 0, y: 20, duration, ease }, 0.2)
  }
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value, () => {
    appear()
  })
})
</script>

<style lang="scss" scoped>
$bp: m;

.keyfacts-text {
  position: relative;
  overflow: hidden;
}

.keyfacts-text__title {
  @extend %text-center;
  @include section-title;

  will-change: opacity;

  @include mq($bp) {
    &:deep(strong) {
      &::before {
        content: '\A';
        white-space: pre;
      }
    }
  }
}

.keyfacts-text__content {
  position: relative;
}

.keyfacts-text__content__items {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  counter-reset: keyfacts;

  @include mq($bp) {
    align-items: flex-start;
  }
}

.keyfacts-text__content__item {
  display: flex;
  align-items: center;
  margin-right: col(1);
  padding: $spacing * 0.5 $spacing $spacing * 0.5 col(1);
  background: var(--c-accent-5);
  border: var(--c-accent) 0.1rem solid;
  border-left: 0;
  border-radius: 0 $spacing * 2 $spacing * 2 0;
  will-change: transform;

  strong {
    @extend %fw-bold;

    color: var(--c-accent);
  }

  & + & {
    margin-top: $spacing * 0.5;
  }

  &::before {
    @extend %fw-bold;
    @include fluid(
      font-size,
      (
        xxs: 1.8rem,
        xxl: 2.4rem,
      )
    );

    content: counter(keyfacts);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    margin-right: $spacing;
    color: $c-white;
    background: var(--c-accent);
    border-radius: 50%;
    counter-increment: keyfacts;
  }

  @include mq(l) {
    margin-right: 0;
    padding: $spacing $spacing * 2 $spacing col(1);
    border-radius: 0 $spacing * 8 $spacing * 8 0;
  }

  @include mq(wrapper) {
    padding-right: $spacing * 4;
    padding-left: calc(get-wrapper-margin() + get-wrapper-column(2));
  }
}

.keyfacts-text__content__picture-outer {
  display: none;

  @include mq(l) {
    position: absolute;
    right: col(1);
    bottom: 0;
    display: block;
    width: col(7, 20);
    height: 90%;
    will-change: opacity;
  }

  @include mq(wrapper) {
    right: get-wrapper-margin();
    width: get-wrapper-column(7);
  }
}

.keyfacts-text__content__picture {
  @include image-fit(contain, right);
}
</style>
